import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../service/user.service';

import { Component, OnInit, Input } from '@angular/core';
import { Patient } from '../../model/patient';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {
  @Input() patient: Patient;

  constructor(
    private route: ActivatedRoute,
    private UserService: UserService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getPatient();
  }

  getPatient(): void {
    const id = +this.route.snapshot.paramMap.get('id');
  }

  goBack(): void {
    this.location.back();
  }
}
