import { NgModule } from '@angular/core';
import { MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule,
  MatToolbarModule, MatMenuModule, MatSelectModule, MatIconModule, MatListModule,
  MatAutocompleteModule, MatSidenavModule, MatStepperModule, MatDialogModule, 
  } from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list'; 

@NgModule({
  imports: [MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule,
    MatToolbarModule, MatMenuModule, MatGridListModule, MatSelectModule, MatIconModule,
    MatListModule, MatSnackBarModule, MatAutocompleteModule, MatSidenavModule,
    MatStepperModule, MatDialogModule, MatProgressSpinnerModule, MatRadioModule, MatTooltipModule],
  exports: [MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule,
    MatToolbarModule, MatMenuModule, MatGridListModule, MatSelectModule, MatIconModule,
    MatListModule, MatSnackBarModule, MatAutocompleteModule, MatSidenavModule,
    MatStepperModule, MatDialogModule, MatProgressSpinnerModule, MatRadioModule, MatTooltipModule],
})
export class MaterialModule { }
