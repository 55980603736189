import { RenderedService } from './rendered-service';
import { Patient } from './patient';
import { Practitioner } from './practitioner';
import { Prescription } from './prescription';

export class HistoryItem {
  date: Date;
  id: number;
  patient: Patient;
  practitioner: Practitioner;
  renderedService: RenderedService;
  prescription: Prescription;
  historyFileType: string;
  fileName: string;
  readings: any;
  type: string;
  handWrittenNotesFile: File;
  handWrittenNotesFileName: string;
}
