import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HistoryItem } from '../model/history-item';
import { Patient } from '../model/patient';
import { StateService } from './state.service';

@Injectable({
    providedIn: 'root'
})
export class UploadFileService {

    constructor(private https: HttpClient, private stateService: StateService) { }

    pushFileToStorage(file: File, documentType: string, patientId: number): Observable<HttpEvent<{}>> {
        let historyItem: HistoryItem = new HistoryItem();
        historyItem.fileName = file.name;
        historyItem.historyFileType = documentType;
        historyItem.patient = new Patient();
        historyItem.patient.id = patientId;
        const data: FormData = new FormData();
        data.append('file', file);
        data.append('historyItem', new Blob([JSON.stringify(historyItem)], {
            type: "application/json"
        }));

        let url = `${this.stateService.getValue('env')['baseUrl']}/history/document/`

        const newRequest = new HttpRequest('POST', url, data, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.https.request(newRequest);
    }
}