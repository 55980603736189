import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConsentItem } from '../model/consent-item';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  constructor(
    private http: HttpClient, private stateService: StateService) { }

  private patientsUrl = 'patient';

  captureConsent(item: ConsentItem) {
    const data: FormData = new FormData();
    data.append('file', item.file);

    data.append('consent', new Blob([JSON.stringify(item)], {
      type: "application/json"
    }));

    return this.http.post<ConsentItem>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.patientsUrl}/${item.patient.id}/consent`,
      data);
  }
}

