import { Component, OnInit } from '@angular/core';
import { Patient } from '../../model/patient';
import { UserService } from '../../service/user.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { StateService } from '../../service/state.service';
import { Address } from '../../model/address';
import { ContactNumbers } from '../../model/contact-numbers';
import { MedicalAidMember } from '../../model/medical-aid-member';
import { ConsentModalComponent } from '../consent-modal/consent-modal.component';
import { MatDialog } from '@angular/material';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {

  maritalStatusses: string[] = ['SINGLE', 'MARRIED'];
  genders: string[] = ['MALE', 'FEMALE'];
  races: string[] = ['BLACK', 'ASIAN', 'WHITE', 'INDIAN'];
  bloodGroups: string[] = ['A', 'B', 'AB', 'O'];
  titles: string[] = ['MISS', 'MR', 'DR', 'MRS'];

  nPatients: Patient[];
  nSearchCtrl = new FormControl();
  patient: Patient = null;

  constructor(private userService: UserService, private router: Router, private stateService: StateService,
    public dialog: MatDialog, private authService: AuthService) { }

  ngOnInit() {
    this.nSearchCtrl.valueChanges.subscribe(selectedVlue => {

      this.userService.searchPatients(selectedVlue)
        .subscribe(patients => this.nPatients = patients.slice(0, 4));
    });

    this.userService.searchPatients('')
      .subscribe(patients => {
        this.nPatients = patients.slice(0, 4);
        this.patient = this.nPatients[0];
      });

    this.patient = new Patient();
    this.patient.medicalAidMember = new MedicalAidMember();
    this.patient.medicalAidMember.address = new Address();
    this.patient.medicalAidMember.medicalAidStatus = {status: "DANGER", reason: "Not enough information"};
    this.patient.contactNumbers = new ContactNumbers();
    this.patient.address = new Address();
  }

  choosePatient(patient: Patient): void {

    this.patient = patient;
  }

  routeTo(patient: Patient, location: string) {

    this.stateService.setValue('patient', patient);
    this.router.navigate([location]);
  }

  save(patient: Patient, detailsForm: any): void {

    this.userService.updatePatient(patient)
      .subscribe( () => {

        this.router.navigate(['patient']);
      },
      (httpError) => {
        if (httpError.status === 400) {
          if (httpError.error.validationErrors) {
            for (const validationError of Object.keys(httpError.error.validationErrors)) {
              if (detailsForm.form.controls[validationError]) {
                detailsForm.form.controls[validationError].setErrors({'server': httpError.error.validationErrors[validationError]});
              }
            }
          }
        }
      }
      );
  }

  openConsentDialog(patient: Patient): void {
    const dialogRef = this.dialog.open(ConsentModalComponent, {
      width: '451px', height: '470px',
      data: { patient: patient, 
        loggedInUser: this.authService.loggedInUser(),
        date: new Date() }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

}
