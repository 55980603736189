import { Injectable } from '@angular/core';
import { Patient } from '../model/patient';
import { Observable } from 'rxjs/Observable';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MedicalAidMember } from '../model/medical-aid-member';
import { ContactNumbers } from '../model/contact-numbers';
import { StateService } from './state.service';
import { Practitioner } from '../model/practitioner';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient, private stateService: StateService) { }

  private patientsUrl = 'patient';
  private practitionerUrl = 'practitioner';
  private medicalAidMemberUrl = 'medical-aid-member';

  searchMedicalAidMember(term: string) {
    return this.http.get<MedicalAidMember[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.medicalAidMemberUrl}/?searchKey=${term}`)
      .pipe(map(members => members.map(member => {
        if (member.contactNumbers == null) {
          member.contactNumbers = new ContactNumbers();
        }
        return member;
      })));
  }

  addPatient(patient: Patient): Observable<Patient> {
    return this.http.post<Patient>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.patientsUrl}`,
      patient,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      });
  }

  updatePatient(patient: Patient): Observable<Patient> {
    return this.http.put<Patient>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.patientsUrl}`,
      patient,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      });
  }

  getPatients(): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.patientsUrl}/`)
      .pipe(map(patients => patients.map(patient => {
        if (patient.contactNumbers == null) {
          patient.contactNumbers = new ContactNumbers();
        }
        return patient;
      })));
  }

  getPractitioners(): Observable<Practitioner[]> {
    return this.http.get<Practitioner[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.practitionerUrl}/`)
      .pipe(map(practitioners => practitioners.map(practitioner => {
        if (practitioner.contactNumbers == null) {
          practitioner.contactNumbers = new ContactNumbers();
        }
        return practitioner;
      })));
  }

  searchPatients(term: string): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.patientsUrl}/?searchKey=${term}`)
      .pipe(map(patients => patients.map(patient => {
        if (patient.contactNumbers == null) {
          patient.contactNumbers = new ContactNumbers();
        }
        return patient;
      })));
  }

  addMedicalAidMember(medicalAidMember: MedicalAidMember) {
    return this.http.post<MedicalAidMember>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.medicalAidMemberUrl}`,
      medicalAidMember,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      });
  }
}

