import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
    HttpEventType
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { StateService } from '../service/state.service';

@Injectable()
export class BusyInterceptor implements HttpInterceptor {

    constructor(private stateService: StateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let lastResponse: HttpEvent<any>;
        let error: HttpErrorResponse;
        this.stateService.setLoading(true, request.url);

        return next.handle(request)
            .pipe(
                tap((response: HttpEvent<any>) => {
                    lastResponse = response;
                }),
                catchError((err) => {
                    error = err;
                    this.stateService.setLoading(false, request.url);
                    return throwError(err);
                }),
                map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                    if (evt instanceof HttpResponse) {
                        this.stateService.setLoading(false, request.url);
                    }
                    return evt;
                }),
                finalize(() => {
                    if (lastResponse.type === HttpEventType.Sent && !error) {
                        this.stateService.setLoading(false, request.url);
                    }
                })
            );
    }
}
