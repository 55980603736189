import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpClient,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StateService } from '../service/state.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private snackBar: MatSnackBar, private router: Router, private http: HttpClient,
        private stateService: StateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {

            let cookieEnable = navigator.cookieEnabled;

            if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnable) {
                document.cookie = 'cookie-test';
                cookieEnable = (document.cookie.indexOf('cookie-test') !== -1);
            }

            if (!cookieEnable) {
                this.snackBar.open('This site need cookies to operate propperly.', null, {
                    duration: 10000,
                });
                return throwError(err);
            }

            if (!err || !err.url || err.url.endsWith('\log')) {
                return throwError(err);
            }

            this.http.post(
                `${this.stateService.getValue('env')['baseUrl']}/log`, {
                'logger': JSON.stringify(navigator.userAgent),
                'auth': JSON.stringify(this.stateService.getValue('authToken')),
                'request': JSON.stringify(request),
                'response': JSON.stringify(err)
            }).subscribe();

            if (err.status === 400) {

                this.snackBar.open(err.error.message, null, {
                    duration: 2000,
                });
            }

            return throwError(err);
        }));
    }
}
