import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsentItem } from '../../model/consent-item';
import { ConsentService } from '../../service/consent.service';
import { Patient } from '../../model/patient';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.css']
})
export class ConsentModalComponent implements AfterViewInit {

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  imagePath: string;

  public signaturePadOptions: any = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 400,
    'canvasHeight': 300
  };

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConsentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private consentService: ConsentService) { }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  clear() {
    this.signaturePad.clear();
  }

  cancel() {
    this.dialogRef.close();
  }

  doneWritting() {    
    const blob: any = this.dataURItoBlob(this.signaturePad.toDataURL());
    blob.name = "handWrittenNotes.png";
    blob.lastModifiedDate = new Date();
    const file: File = <File>blob;

    let item: ConsentItem = new ConsentItem();
    item.file = file;
    item.patient = this.data.patient;

    this.consentService.captureConsent(item)
    .subscribe(result => {
      this.snackBar.open('Consent has been captured', null, {duration: 2000});
      this.dialogRef.close();
    });
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    let ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    let blob = new Blob([ab], { type: mimeString });
    return blob;
  }
}
