import { Component, OnInit } from '@angular/core';
import { SystemUser } from '../../model/system-user';
import { Role } from '../../model/role';
import { BackofficeService } from '../../service/backoffice.service';

@Component({
  selector: 'app-roles-users',
  templateUrl: './roles-users.component.html',
  styleUrls: ['./roles-users.component.css']
})
export class RolesUsersComponent implements OnInit {

  users: SystemUser[];
  roles: Role[];
  selectedRole: Role;
  userRoles: Map<number, Role[]> = new Map();
  selectedUser: SystemUser = new SystemUser();

  constructor(private backofficeService: BackofficeService) { }

  ngOnInit() {

    this.backofficeService.retrieveUsers().subscribe(result => {
      this.users = result;
      this.selectedUser = this.users[0];
      this.users.forEach(user => this.userRoles[user.id] = user.roles)
    });

    this.backofficeService.retrieveRoles().subscribe(result => {
      this.roles = result;
    });
  }

  addUserRole(user: SystemUser, role: Role) {
    this.backofficeService.addUserRole(user.id, role).subscribe(result => {
      this.userRoles[user.id] = result;
    });
  }

  removeUserRole(user: SystemUser, role: Role) {
    this.backofficeService.deleteUserRole(user.id, role.id).subscribe(result => {
      this.userRoles[user.id] = result;
    });
  }
}
