import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading === true) {
      this.loadingMap.set(url, loading);
      this.loadingSubject.next(true);
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {
      this.loadingSubject.next(false);
    }
  }

  localStorageEnabled = false;

  constructor(private snackBar: MatSnackBar) {
    try {
      if (typeof localStorage !== 'undefined') {
        try {
          localStorage.setItem('feature_test', 'yes');
          if (localStorage.getItem('feature_test') === 'yes') {
            localStorage.removeItem('feature_test');
            // localStorage is enabled
            this.localStorageEnabled = true;
          } else {
            // localStorage is disabled
            this.snackBar.open('This site needs local storage to operate properly.', null, {
              duration: 10000,
            });
          }
        } catch (e) {
          // localStorage is disabled
          this.snackBar.open('This site needs local storage to operate properly.', null, {
            duration: 10000,
          });
        }
      } else {
        // localStorage is not available
        this.snackBar.open('This site needs local storage to operate properly.', null, {
          duration: 10000,
        });
      }
    } catch (e) {
      // localStorage is not available
      this.snackBar.open('This site needs local storage to operate properly.', null, {
        duration: 10000,
      });
    }
  }

  getValue(key: string): any {

    if (localStorage.getItem(key) !== null) {

      return JSON.parse(localStorage.getItem(key));
    }

    return null;
  }

  setValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  clearValue(key: string) {
    localStorage.removeItem(key);
  }
}
