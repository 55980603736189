import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpHeaders,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StateService } from '../service/state.service';
import { KJUR } from 'jsrsasign';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

    constructor(private snackBar: MatSnackBar, private router: Router,
        private stateService: StateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //add Authorisation header in case of auth based on the header
        if (this.stateService.getValue('unparsedAuthToken')) {

            if (!request.url.endsWith('login') && !request.url.endsWith('logout')) {
                request = request.clone(
                    {
                        headers: new HttpHeaders({
                            'Authorization': this.stateService.getValue('unparsedAuthToken')
                        })
                    }
                );
            }
        }

        //add with credentials header in case auth based on cookies
        request = request.clone({
            withCredentials: true
        });

        return next.handle(request)
            .pipe(
                tap(res => {
                    if (res instanceof HttpResponse) {
                        const token = res.headers.get('Refresh-Token');
                        if (!token) {
                            return;
                        }
                        console.log("Refresh token found and refreshing.");
                        const authToken = KJUR.jws.JWS.parse(token);

                        authToken['authorities'] = authToken.payloadObj['AUTHORITIES_KEY']
                            .split(',');

                        this.stateService.setValue('authToken', authToken);
                        this.stateService.setValue('unparsedAuthToken', token);
                    }
                }),
                catchError(err => {

                    if (err.status === 401 || err.status === 403) {

                        let message = err.status === 401 ? "You need to log in" : "You do not have permission to perform that function";
                        
                        if (err.error && err.error.message) {
                            message = err.error.message;
                        }

                        this.snackBar.open(message, null, {
                            duration: 2000,
                        });

                        if (err.status === 401) {
                            this.router.navigate(['login']);
                        }
                    }

                    return throwError(err);
                })
            );
    }
}
