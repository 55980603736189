import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { HistoryItem } from '../model/history-item';
import { ReferralItem } from '../model/referral-item';
import { StateService } from './state.service';
import { Patient } from '../model/patient';
import { Practitioner } from '../model/practitioner';

@Injectable({
  providedIn: 'root'
})
export class ConsultationService {
  private consultationUrl = '/history/consultation/';
  private referralUrl = '/referral/';
  private readingsUrl = '/history/readings/';
  private documentUrl = '/history/document/';

  constructor(private http: HttpClient, private stateService: StateService) { }

  downloadHandWrittenNotes(consulationId: number): Observable<any> {
    const location = `${this.stateService.getValue('env')['baseUrl']}${this.consultationUrl}${consulationId}/handWritttenNotes`;
    return this.downloadFile(location);
  }

  downloadHistoryDocument(historyId: number): Observable<any> {
    const location = `${this.stateService.getValue('env')['baseUrl']}${this.documentUrl}${historyId}/document`;
    return this.downloadFile(location);
  }

  downloadConsultation(consultationId: number): Observable<any> {
    const location = `${this.stateService.getValue('env')['baseUrl']}${this.consultationUrl}${consultationId}/document`;
    return this.downloadFile(location);
  }

  downloadFile(location: string): Observable<any> {
    return this.http
      .get(location, { observe: 'response', responseType: 'blob' })
      .pipe(map((response) => {

        var headers = response.headers;
        var contentDisposition = headers.get('content-disposition');
        let fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, "");

        return {
          filename: fileName,
          data: response.body
        };
      }));
  }

  getConsultations(patientId: number): Observable<HistoryItem[]> {
    return this.http.get<HistoryItem[]>(
      `${this.stateService.getValue('env')['baseUrl']}${this.consultationUrl}?patient-id=${patientId}`);
  }

  getHistory(patientId: number): Observable<HistoryItem[]> {
    return this.http.get<HistoryItem[]>(
      `${this.stateService.getValue('env')['baseUrl']}/history/?patientId=${patientId}`)
      .pipe(map(items => items.map(item => {
        item.type = item.historyFileType ? 'DOCUMENT' : (item.readings ? 'READING' : 'CONSULTATION')
        return item
      })));
  }

  completeReferral(patient: Patient, practitioner: Practitioner): Observable<ReferralItem> {
    return this.http.post<ReferralItem>(
      `${this.stateService.getValue('env')['baseUrl']}${this.referralUrl}`,
      {patientId: patient.id, practitionerId: practitioner.id},
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      });
  }

  completeConsultation(consulatation: HistoryItem): Observable<HistoryItem> {

    const data: FormData = new FormData();
    if (consulatation.handWrittenNotesFile) {
      data.append('file', consulatation.handWrittenNotesFile);
    }

    data.append('consulatation', new Blob([JSON.stringify(consulatation)], {
      type: "application/json"
    }));

    return this.http.post<HistoryItem>(
      `${this.stateService.getValue('env')['baseUrl']}${this.consultationUrl}`,
      data);
  }

  saveReadings(readings: HistoryItem): Observable<HistoryItem> {
    return this.http.post<HistoryItem>(
      `${this.stateService.getValue('env')['baseUrl']}${this.readingsUrl}`,
      readings);
  }
}

