import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HttpClientModule } from '@angular/common/http';


import { AppComponent } from './component/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { PatientsComponent } from './component/patients/patients.component';
import { AppointmentComponent } from './component/appointment/appointment.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecurityInterceptor } from './interceptor/security.interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { BusyInterceptor } from './interceptor/busy.interceptor';
import { LoginComponent } from './component/login/login.component';
import { TriageComponent } from './component/triage/triage.component';
import { DiagnoseComponent } from './component/diagnose/diagnose.component';
import { PatientHistoryComponent } from './component/patient-history/patient-history.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AuthService } from './service/auth.service';
import { PatientAddComponent } from './component/patient-add/patient-add.component';
import { ConsentModalComponent } from './component/consent-modal/consent-modal.component';
import { RolesUsersComponent } from './component/roles-users/roles-users.component';
import { CreateUserComponent } from './component/create-user/create-user.component';
import { BoLandingComponent } from './component/bo-landing/bo-landing.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PatientsComponent,
    AppointmentComponent,
    TriageComponent,
    DiagnoseComponent,
    PatientHistoryComponent,
    PatientAddComponent,
    ConsentModalComponent,
    RolesUsersComponent,
    CreateUserComponent,
    BoLandingComponent
  ],
  entryComponents: [ 
    ConsentModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    AppRoutingModule,
    HttpClientModule,
    SignaturePadModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BusyInterceptor,
      multi: true
    },
    AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
