import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { StateService } from './state.service';
import { SystemUser } from '../model/system-user';
import { Role } from '../model/role';

@Injectable({
  providedIn: 'root'
})
export class BackofficeService {
  deleteUserRole(userId: number, roleId: number): Observable<Role[]>  {
    return this.http.delete<SystemUser[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.systemUserUrl}/${userId}/role/${roleId}`);
  }

  addUserRole(userId: number, role: Role): Observable<Role[]> {
    return this.http.post<Role[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.systemUserUrl}/${userId}/role/`,
      role,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      });
  }
  constructor(
    private http: HttpClient, private stateService: StateService) { }

  private systemUserUrl = 'system-user';
  private rolesUrl = 'role';

  retrieveUsers(): Observable<SystemUser[]> {
    return this.http.get<SystemUser[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.systemUserUrl}/`);
  }

  retrieveRoles(): Observable<Role[]> {
    return this.http.get<SystemUser[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.rolesUrl}/`);
  }

  addSystemUser(systemUser: SystemUser): Observable<SystemUser>  {
    return this.http.post<SystemUser>(
        `${this.stateService.getValue('env')['baseUrl']}/${this.systemUserUrl}/`,
        systemUser,
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        });
  }
}
