import { Address } from './address';
import { ContactNumbers } from './contact-numbers';

export class ContactableUser {
    id: number;
    lastName: string;
    firstName: string;
    title: string;
    name: string;
    idNumber: string;
    address: Address;
    contactNumbers: ContactNumbers;
}
