import { Patient } from './patient';
import { SystemUser } from './system-user';

export class ConsentItem {
  id: number;
  date: Date;
  patient: Patient;
  capturer: SystemUser;
  file: File;
  fileName: string;
}
