import { Component } from '@angular/core';
import { Patient } from '../../model/patient';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { MedicalAidMember } from '../../model/medical-aid-member';
import { Address } from '../../model/address';
import { ContactNumbers } from '../../model/contact-numbers';
import { ContactableUser } from '../../model/contactable-user';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.css']
})
export class PatientAddComponent {
  memberSearchControl = new FormControl();
  members = [];
  filteredMembers: Observable<MedicalAidMember[]>;
  maritalStatusses: string[] = ['SINGLE', 'MARRIED'];
  genders: string[] = ['MALE', 'FEMALE'];
  races: string[] = ['BLACK', 'ASIAN', 'WHITE', 'INDIAN'];
  bloodGroups: string[] = ['A', 'B', 'AB', 'O'];
  titles: string[] = ['MISS', 'MR', 'DR', 'MRS'];
  yesNoAnswers = [{ value: "false", display: "No" }, { value: "true", display: "Yes" }];
  addingNewMember: Boolean;

  patient: Patient;
  medicalAidMember: MedicalAidMember;

  constructor(private userService: UserService, private router: Router) {

    this.patient = new Patient();
    this.patient.address = new Address();
    this.patient.contactNumbers = new ContactNumbers();

    this.medicalAidMember = new MedicalAidMember();
    this.medicalAidMember.address = new Address();
    this.medicalAidMember.contactNumbers = new ContactNumbers();

    this.filteredMembers = this.memberSearchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this.filter(val || '')
      })
    )
  }

  filter(val: string): Observable<MedicalAidMember[]> {
    return this.userService.searchMedicalAidMember(val);
  }

  selectMedicalAidMember(value: MedicalAidMember) {
    this.medicalAidMember = value;
    this.patient.medicalAidMember = value;
  }

  addMedicalAidMember(medicalAidMember: MedicalAidMember, memberForm: any) {

    if (!medicalAidMember.contactNumbers.cellNumber && !medicalAidMember.contactNumbers.email
      && !medicalAidMember.contactNumbers.homeNumber && !medicalAidMember.contactNumbers.businessNumber) {
      memberForm.form.controls['cellNumber'].setErrors({ 'server': 'Must have at least one contact detail' });
      memberForm.form.controls['email'].setErrors({ 'server': 'Must have at least one contact detail' });
      memberForm.form.controls['homeNumber'].setErrors({ 'server': 'Must have at least one contact detail' });
      memberForm.form.controls['businessNumber'].setErrors({ 'server': 'Must have at least one contact detail' });
      return;
    } else {
      memberForm.form.controls['cellNumber'].setErrors(null);
      memberForm.form.controls['email'].setErrors(null);
      memberForm.form.controls['homeNumber'].setErrors(null);
      memberForm.form.controls['businessNumber'].setErrors(null);
    }

    /* TODO: remove hard coding of employer */
    medicalAidMember.employer = new ContactableUser();
    medicalAidMember.employer.id = 1;

    this.userService.addMedicalAidMember(medicalAidMember)
      .subscribe((result) => {

        this.patient.medicalAidMember = result;
        this.addingNewMember = false;
      },
        (httpError) => {

          if (httpError.status === 400) {
            if (httpError.error.validationErrors) {
              for (const validationError of Object.keys(httpError.error.validationErrors)) {
                if (memberForm.form.controls[validationError]) {
                  memberForm.form.controls[validationError].setErrors({ 'server': httpError.error.validationErrors[validationError] });
                }
              }
            }
          }
        }
      );
  }

  add(patient: Patient, detailsForm: any): void {

    this.userService.addPatient(patient)
      .subscribe(() => {

        this.router.navigate(['patient']);
      },
        (httpError) => {

          if (httpError.status === 400) {
            if (httpError.error.validationErrors) {
              for (const validationError of Object.keys(httpError.error.validationErrors)) {
                if (detailsForm.form.controls[validationError]) {
                  detailsForm.form.controls[validationError].setErrors({ 'server': httpError.error.validationErrors[validationError] });
                }
              }
            }
          }
        }
      );
  }

  displayFn(member: MedicalAidMember) {
    if (!member) {
      return '';
    }
    return member.name;
  }

  startNewMemberMode() {
    this.addingNewMember = true;
    this.medicalAidMember = new MedicalAidMember();
    this.medicalAidMember.address = new Address();
    this.medicalAidMember.contactNumbers = new ContactNumbers();
  }

}
