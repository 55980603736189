import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FormBuilder } from '@angular/forms';
import { StateService } from '../../service/state.service';

import { delay } from 'rxjs/operators';
import { interval } from 'rxjs/observable/interval';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'National Software Integration Programme';
  submitForm: any;
  isLoading: boolean = false;
  distance: number = 30;
  expiring: boolean = false;

  constructor(private authService: AuthService, private snackBar: MatSnackBar,
    private formBuilder: FormBuilder, private router: Router, private stateService: StateService,
    private http: HttpClient) {

    this.submitForm = this.formBuilder.group({
      username: '',
      password: ''
    });

    this.http.get('assets/server-environment.json')
    .subscribe(result => {
      console.log('Environment file loaded successfully');
      this.stateService.setValue('env', result);
    },
      (err) => {
        console.error('Error loading environment file.');
        console.error(err);
      }
    );
  }

  ngOnInit(): void {
    this.listenToLoading();

    const source = interval(1000);
    const subscribe = source.subscribe(val => {
      let authToken = this.stateService.getValue('authToken');
      if (authToken) {
        let countDownDate = authToken.payloadObj.exp;
        const now = Math.floor(new Date().getTime() / 1000);
        this.distance = countDownDate - now;

        this.expiring = this.distance < 15;

        if (this.distance < 0) {
          this.authService.logout().subscribe(result => {
            this.router.navigate(['login']);
            this.snackBar.open('You are now logged out', null, {
              duration: 2000,
            });
          });
        }
      }
    });
  }

  refreshToken(): void {
    this.authService.refresh().subscribe();
  }

  logout(): void {

    this.authService.logout().subscribe(result => {
      this.router.navigate(['login']);
      this.snackBar.open('You are now logged out', null, {
        duration: 2000,
      });
    });
  }

  hasRole(role: string): boolean {

    return this.authService.hasRole(role);
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  listenToLoading(): void {
    this.stateService.loadingSubject
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }
}
