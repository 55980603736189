// @ts-ignore
// @ts-ignore

import { Component, OnInit } from '@angular/core';
import { SystemUser } from '../../model/system-user';
import { NgForm } from '@angular/forms';
import { Address } from '../../model/address';
import { ContactNumbers } from '../../model/contact-numbers';
import { BackofficeService } from '../../service/backoffice.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  systemUser: SystemUser;
  titles: string[] = ['MISS', 'MR', 'DR', 'MRS'];
  constructor(private backofficeService: BackofficeService) { }

  ngOnInit() {
    this.systemUser = new SystemUser();
    this.systemUser.address = new Address();
    this.systemUser.contactNumbers = new ContactNumbers();
  }

  addSystemUser(systemUser: SystemUser, userForm: NgForm) {

    if (!systemUser.contactNumbers.cellNumber && !systemUser.contactNumbers.email
        && !systemUser.contactNumbers.homeNumber && !systemUser.contactNumbers.businessNumber) {
      userForm.form.controls['cellNumber'].setErrors({'server': 'Must have at least one contact detail'});
      userForm.form.controls['email'].setErrors({'server': 'Must have at least one contact detail'});
      userForm.form.controls['homeNumber'].setErrors({'server': 'Must have at least one contact detail'});
      userForm.form.controls['businessNumber'].setErrors({'server': 'Must have at least one contact detail'});
      return;
    } else {
      userForm.form.controls['cellNumber'].setErrors(null);
      userForm.form.controls['email'].setErrors(null);
      userForm.form.controls['homeNumber'].setErrors(null);
      userForm.form.controls['businessNumber'].setErrors(null);

      this.backofficeService.addSystemUser(systemUser)
          .subscribe((result) => {
                this.systemUser = result;
              },
              (httpError) => {
                if (httpError.status === 400) {
                  if (httpError.error.validationErrors) {
                    for (const validationError of Object.keys(httpError.error.validationErrors)) {
                      if (userForm.form.controls[validationError]) {
                        userForm.form.controls[validationError].setErrors({'server': httpError.error.validationErrors[validationError]});
                      }
                    }
                  }
                }
              }
          );
    }
  }
}
