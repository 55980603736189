import { Injectable } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


import { LoginResponse } from '../model/login-response';
import { SystemUser } from '../model/system-user';

import { KJUR } from 'jsrsasign';
import { StateService } from '../service/state.service';

@Injectable()
export class AuthService {

  private loginRequestUrl = 'security/login';
  private logoutRequestUrl = 'security/logout';
  private refreshRequestUrl = 'security/refresh';

  refresh() {
    return this.http.get<any>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.refreshRequestUrl}`)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  logout() {
    console.log("loggin out");
    return this.http.delete<any>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.logoutRequestUrl}`)
      .pipe(
        tap(_ => {
          this.clearProfile();
        }),
        catchError(error => throwError(error))
      );
  }

  login(loginRequest: SystemUser) {
    return this.http.post<LoginResponse>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.loginRequestUrl}`, loginRequest)
      .pipe(
        tap(res => {
          const authToken = KJUR.jws.JWS.parse(res.token);

          authToken['authorities'] = authToken.payloadObj['AUTHORITIES_KEY']
            .split(',');

          this.stateService.setValue('authToken', authToken);
          this.stateService.setValue('unparsedAuthToken', res.token);
        }),
        catchError(error => throwError(error))
      );
  }

  clearProfile() {

    this.stateService.clearValue('authToken');
    this.stateService.clearValue('unparsedAuthToken');
  }

  constructor(private http: HttpClient, private stateService: StateService) { }

  getAuthToken() {

    return this.stateService.getValue('authToken');
  }

  isLoggedIn(): boolean {
    return this.getAuthToken() !== null;
  }

  loggedInUser(): string {
    if (this.isLoggedIn()) {

      return this.getAuthToken().payloadObj['sub'];
    }

    return null;
  }

  hasRole(role: string) {

    if (!this.isLoggedIn()) {
      return false;
    }
    return (this.getAuthToken()['authorities'].includes(role));
  }
}
