import { Component, OnInit } from '@angular/core';
import { ConsultationService } from '../../service/consultation.service';
import { Patient } from '../../model/patient';
import { StateService } from '../../service/state.service';
import { Practitioner } from '../../model/practitioner';
import { MedicalAidMember } from '../../model/medical-aid-member';
import { Address } from '../../model/address';
import { Router } from '@angular/router';
import { HistoryItem } from '../../model/history-item';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.css']
})
export class PatientHistoryComponent implements OnInit {

  patient: Patient = new Patient();
  member: MedicalAidMember = new MedicalAidMember();
  practitioner: Practitioner = new Practitioner();

  historyItem: HistoryItem = null;
  historyItems: HistoryItem[];

  handWrittenNotesImage: any;

  constructor(
    private stateService: StateService, private consultationService: ConsultationService, private router: Router,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.patient = this.stateService.getValue('patient');
    this.consultationService.getHistory(this.patient.id)
      .subscribe(data => this.historyItems = data);
  }

  chooseHistoryItem(historyItem: HistoryItem) {
    this.historyItem = historyItem;

    if (historyItem.handWrittenNotesFileName) {
      this.downloadHandWrittenNotes(historyItem);
    }
  }

  sortBy(arr: any[], prop: string) {
    if (!arr) return null;
    return arr.sort((a, b) => a[prop] > b[prop] ? -1 : a[prop] === b[prop] ? 0 : 1);
  }

  addressToString(address: Address) {
    return `${address.houseNumber}, ${address.streetAddress}, ${address.province}, ${address.city}, ${address.postalCode}`;
  }

  downloadHandWrittenNotes(historyItem: HistoryItem) {
    let observable: Observable<any>;

    observable = this.consultationService.downloadHandWrittenNotes(historyItem.id);

    observable.subscribe(response => {

      var binaryData = [];
      binaryData.push(response.data);
      let blob: Blob = new Blob(binaryData, { type: "image/png" });
      let reader = new FileReader();
      let file = new File([blob], response.filename+'.'+   blob.type.split('/')[1]) ;
      reader.readAsDataURL(file);
      reader.onloadend = () => {
    
        this.handWrittenNotesImage = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
      }
    }, error => {

      console.error(error);
    });
  }

  downloadFile(historyItem: HistoryItem) {

    let observable: Observable<any>;

    if (historyItem.type == 'CONSULTATION') {
      observable = this.consultationService.downloadConsultation(historyItem.id);
    } else if (historyItem.type == 'DOCUMENT') {
      observable = this.consultationService.downloadHistoryDocument(historyItem.id);
    }

    observable.subscribe(response => {

      var binaryData = [];
      binaryData.push(response.data);
      var url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.setAttribute('target', 'blank');
      a.href = url;
      a.download = response.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

    }, error => {

      console.error(error);
    });
  }

  routeTo(patient: Patient, location: string) {

    this.stateService.setValue('patient', patient);
    this.router.navigate([location]);
  }
}
