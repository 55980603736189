import { Component } from '@angular/core';
import { Patient } from '../../model/patient';
import { Router } from '@angular/router';
import { StateService } from '../../service/state.service';
import { HistoryItem } from '../../model/history-item';
import { ConsultationService } from '../../service/consultation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { MatIconRegistry } from '@angular/material';

@Component({
  selector: 'app-triage',
  templateUrl: './triage.component.html',
  styleUrls: ['./triage.component.css']
})
export class TriageComponent {
  submitForm: any;

  constructor(private consultationService: ConsultationService, private router: Router, private stateService: StateService,
    private snackBar: MatSnackBar, private formBuilder: FormBuilder) {

    this.patient = this.stateService.getValue('patient');

    this.submitForm = this.formBuilder.group({
      temperature: '',
      systolic: '',
      dyastolic: ''
    });
  }

  patients: Patient[] = [];
  patient: Patient = new Patient();

  routeTo(patient: Patient, location: string) {

    this.stateService.setValue('patient', patient);
    this.router.navigate([location]);
  }

  onSubmit(requestData: any) {

    let item: HistoryItem = new HistoryItem();
    item.readings = requestData;
    item.patient = this.patient;

    this.consultationService.saveReadings(item)
      .subscribe(() => this.snackBar.open('Readings have been captured', null, {
        duration: 2000,
      }));
  }

  choosePatient(patient: Patient): void {

    this.patient = patient;
  }
}
