import { ContactableUser } from './contactable-user';
import { MedicalAidMember } from './medical-aid-member';

export class Patient extends ContactableUser {
    maritalStatus: string;
    age: number;
    gender: string;
    race: string;
    nationality: string;
    bloodGroup: string;
    organDonor: string;
    disabilities: string;
    occupation: string;
    medicalAidMember: MedicalAidMember;
    popiStatus: string;
}
