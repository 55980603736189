import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../service/auth.service';
import { SystemUser } from '../../model/system-user';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  submitForm: any;

  constructor(private authService: AuthService, private formBuilder: FormBuilder,
    private snackBar: MatSnackBar, private router: Router) {

    this.submitForm = this.formBuilder.group({
      username: '',
      password: ''
    });
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  onSubmit(requestData: any) {

    const loginRequest: SystemUser = new SystemUser();
    loginRequest.username = requestData.username;
    loginRequest.password = requestData.password;

    this.authService.login(loginRequest).subscribe(() => {

      if (this.authService.hasRole('ROLE_BACKOFFICE')) {
        this.router.navigate(['backoffice/bo-landing']);;
      } else {
        this.router.navigate(['patient']);
      }
      this.snackBar.open('You are now logged in', null, {
        duration: 2000,
      });
    });
  }

}
