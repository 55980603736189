import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { PatientsComponent } from './component/patients/patients.component';
import { AppointmentComponent } from './component/appointment/appointment.component';
import { TriageComponent } from './component/triage/triage.component';
import { DiagnoseComponent } from './component/diagnose/diagnose.component';
import { PatientHistoryComponent } from './component/patient-history/patient-history.component';
import { PatientAddComponent } from './component/patient-add/patient-add.component';
import { RolesUsersComponent } from './component/roles-users/roles-users.component';
import { CreateUserComponent } from './component/create-user/create-user.component';
import { BoLandingComponent } from './component/bo-landing/bo-landing.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'patient', component: PatientsComponent},
  { path: 'appointment', component: AppointmentComponent },
  { path: 'triage', component: TriageComponent },
  { path: 'diagnose', component: DiagnoseComponent },
  { path: 'patient-history', component: PatientHistoryComponent },
  { path: 'patient-add', component: PatientAddComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'backoffice/bo-landing', component: BoLandingComponent },
  { path: 'backoffice/roles-users', component: RolesUsersComponent },
  { path: 'backoffice/create-user', component: CreateUserComponent },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})

export class AppRoutingModule { }
